import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, HStack, Text } from '@chakra-ui/react';
import { PageProps, graphql } from 'gatsby';

import Layout from '../components/Base/Layout'
import React from 'react'
import { SEO } from '../components/Base/Seo';
import { SvgBackground } from '../components/Base/SvgBackground';

const FAQ: React.FC<FAQ> = ({ data }) => {
    
    // const {
    //     wpPage: {
    //         faq: { title, description, questionsRepeater, questionsRepeater: [{question, answer}]}
    //     }, 
    // } = data

  return (
      <Layout>
        <SvgBackground position="absolute" bottom="0" right="0"></SvgBackground>
        <Box bg="gray.50" as="section" minHeight="605px" margin="auto" px={28} pb={8} pt={24} >
        <HStack position="relative" minHeight="inherit" flexDirection={{base: "column", md: "row"}} justifyContent={{base: "center", md: "space-between"}} alignItems={{ base: "center", md:'flex-start'}}>
            <Box pb="4">
                <Text textAlign={{base: "center", md: "left"}} fontSize="lg" fontWeight="900" color='gray.900'>
                    {data.wpPage.faq.title}
                </Text>
                <Text textAlign={{base: "center", md: "left"}} color='gray.800' dangerouslySetInnerHTML={{ __html: data.wpPage.faq.description }}></Text>
            </Box>
            <Accordion allowMultiple bg="white" borderRadius="lg" w={{base: "100%",md:"588px"}} p="4" boxShadow="0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);">
                {data.wpPage.faq.questionsRepeater.map((item) => {
                    return (
                        <AccordionItem borderColor="rgb(158, 169, 188, 0.2)">
                            <h2>
                            <AccordionButton fontWeight="500" color="gray.700" _expanded={{ color: 'yellow.600' }}>
                                <Box flex='1' textAlign='left'>
                                    {item.question}
                                    </Box>
                                    <AccordionIcon width="24px" height="24px" />
                                </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4} color="gray.700" dangerouslySetInnerHTML={{ __html: item.answer }}>
                               
                                </AccordionPanel>
                        </AccordionItem>
                    )
                })}
            </Accordion>
            </HStack>
        </Box>
    </Layout>
  )
}

type FAQ = PageProps<FAQProps>

type FAQProps = {
    wpPage: {
        faq: {
            title: string,
            description: string, 
            questionsRepeater: [
                {
                    question: string, 
                    answer: string
                }
            ]
        }
    }
}

export const query = graphql`
  {
    wpPage(slug: {eq: "faq"}) {
        faq {
        title
        description
        questionsRepeater {
            answer
            question
        }
    }
    }
  }
`
export default FAQ

export const Head = () => (
  <SEO title="AceMedical Wholesale | FAQ" description="We are here to answer all your questions about our shop." />
)