import {
    chakra, 
    HTMLChakraProps,
    IconProps,
    Icon,
    useBreakpointValue,
} from '@chakra-ui/react'
import * as React from 'react'

export const SvgBackground = (props: IconProps) => {
    return (
    <Icon
       width="484" height="600" viewBox="0 0 484 600" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
 <g opacity="0.2" clip-path="url(#clip0_62_2552)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M306.263 276.249L241.629 109.733L168.873 298.208L147.706 355.367L61.8869 571.928L51.181 600H0V557.385V112.217H27.9955L108.815 229.249L82.9729 289.986L61.086 257.801V405.208L227.738 0H256.842L346.706 219.498L306.263 276.249ZM242.29 424.869L454.602 112.217H483.258V556.941V600H432.077L421.421 571.887L356.009 405.738L393.489 349.321L397.548 342.769L422.172 403.086V258.661L248.905 516.29H233.693L161.113 406.824L173.887 371.421L174.014 370.878L174.131 370.647L185.317 340.077L242.29 424.869Z" fill="#61686B"/>
</g>
<defs>
<clipPath id="clip0_62_2552">
<rect width="483.258" height="600" fill="white"/>
</clipPath>
</defs>
    </Icon>
    );
};